/**
 * @description - The file contains the view and form in order to create a documentation.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
// React
import { useEffect, useMemo, useCallback, useState, useRef } from 'react';
// Parameters
import { useParams } from 'react-router-dom';
// Notification bar
import { useSnackbar } from "notistack";
import {styled} from "@mui/material/styles";
// Material Components
import {
  Dialog,
  Button,
  Box,
  Card,
  Stack,
  Grid,
  MenuItem,
  Menu,
  StepConnector,
  Stepper,
  Step,
  StepLabel,
  Divider, Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getFirestore, setDoc, updateDoc, doc, Timestamp, onSnapshot } from "firebase/firestore";
// PDF viewer
import {
  Viewer,
  LoadError,
  RenderPage,
  RenderPageProps,
  DocumentLoadEvent,
  SpecialZoomLevel, ScrollMode, ViewMode, PageChangeEvent
} from '@react-pdf-viewer/core';
// Yup
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// Form
import { useForm } from "react-hook-form";
// Redux related
import { useDispatch, useSelector } from "src/redux/store";
// Hooks
import { useAuth, useLocales, useLocalStorage } from "src/hooks";
// Local components
import { Iconify, FormProvider, RHFSelect, LoadingScreen, RHFUploadSingleFile, Image } from 'src/components';
import PartiesContactForm from '../components/parties';
// Document types
import { TypeDocumentView, ModelNewContractForm, ModelContact } from 'src/@types';
// Slices/services
import { getPlatformProjects, createContractByAttachment } from "src/redux/slices";
// Services
import {
  fetchDocumentByUid,
  changeContractByUid,
  sendEmail,
  updateDocument,
  fetchDocumentByUidNew, sendSmsService
} from "src/services/documents/documents";
import SignatureDrawer from "src/pages/docs/views/SignatureDrawer";
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import { PATH_APP_HELP } from "src/routes";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import '@react-pdf-viewer/core/lib/styles/index.css';
import './custom.css';
import { ContactInfoProps } from "src/pages/docs/views/DocsPartiesView";
import { DocumentNewContactDialog } from "src/pages/docs/dialogs";
import { generateCollectionReference } from "src/services/documents/queries";
import {getTemplateData, getTemplateRTData} from "src/services";
import * as React from "react";
import {pageNavigationPlugin} from "@react-pdf-viewer/page-navigation";

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //
/**
 * @description - The method is a Document Creation View component that's need to create a documentation, contract
 * or act.
 * @constructor
 */
export default function DocEditRTView() {
  const pdfContainerRef = useRef(null); // Ref to the component
  const accountSid = 'AC12e8e0dd154fb84a76c39492e7577ece';
  const authToken = '360db3e33607dc61c621b63286a40a73';
  // const client = require('twilio')(accountSid, authToken);
  // ======================================== THE VIEW DATA INITIALIZATION ========================================== //
  // Notification bar
  const { enqueueSnackbar } = useSnackbar();
  // Create an instance of the pageNavigationPlugin
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const [pageNumber, setPageNumber] = useState(0);
  const handlePageChange = (e: PageChangeEvent) => {
    setPageNumber(e.currentPage);
  };
  // Extract the specific components you want to use from the plugin
  const { GoToFirstPage, GoToLastPage, GoToNextPage, GoToPreviousPage, CurrentPageInput, NumberOfPages } = pageNavigationPluginInstance;
  const { translate } = useLocales();
  const containerRef = useRef<HTMLDivElement | null>(null); // Reference to the container
  // Dispatch
  const dispatch = useDispatch();
  // Get user and companies
  const { user } = useAuth();
  // Selected company
  const [selectedCompany, setSelectedCompany] = useLocalStorage('company', undefined);
  // The global state
  const { projects, isLoadingProjects, projectFetchingError } = useSelector((state) => state.platformProjects);
  const { isLoadingContracts, contractFetchingError, contract } = useSelector((state) => state.contracts);
  const [pdfFile, setPdfFile] = useState<any|null>(null);
  const [allowedMouseMove, setAllowedMouseMove] = useState<string|null>(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  // Handle mouse movement
  const [isUploading, setIsUploading] = useState(false);
  const { id } = useParams();
  const [signatureDialog, setSignatureDialog] = useState(false);
  const firestoreDb = getFirestore();

  useEffect(() => {
    if (!pdfFile && id && selectedCompany) {
      fetchDocumentByUidNew(id, selectedCompany.uid, setPdfFile, (error) => console.log(error));
    }
  }, []);

  const [templateData, setTemplateData] = useState<Record<string, any> | null>(null);
  useEffect(() => {
    if (!templateData && pdfFile?.template_uid) {
      getTemplateRTData(pdfFile.template_uid, setTemplateData);
    }
  }, [pdfFile]);

  useEffect(() => {
    const documentsDbReference = doc(generateCollectionReference('contract'), id);
    const unsubscribe = onSnapshot(documentsDbReference, (dataSnapshot) => {
      if (id) {
        const documentDataOriginal = dataSnapshot.data();
        // if (documentDataOriginal?.documents === 'string') {
        //   const fileUrl = `pf0ArUxSiZNwqXZnun6rVZSkrRH2/contract/${id}/${documentDataOriginal?.documents}`;
        //   console.log(fileUrl);
        //   const storage = getStorage();
        //   const pdfRef = ref(storage, fileUrl);
        //   getDownloadURL(pdfRef).then((url) => {
        //     // This can be downloaded directly:
        //     const xhr = new XMLHttpRequest();
        //     xhr.responseType = 'blob';
        //     xhr.onload = (event) => {
        //       const blob = xhr.response;
        //     };
        //     xhr.open('GET', url);
        //     console.log(url);
        //     xhr.send();
        //     // ToDo add profile parsing method
        //     setPdfFile({
        //       ...pdfFile,
        //       documents: url,
        //     });
        //   }).catch((error)  => console.error(error));
        // } else {
        //   setPdfFile(documentDataOriginal);
        // }
        fetchDocumentByUidNew(id, selectedCompany.uid, setPdfFile, (error) => console.log(error));
      }
    });

    return () => unsubscribe();
  }, [id]);

  const renderError = (error: LoadError) => {

    return <div>
      {error.message}
    </div>
  }

  const renderPage: RenderPage = (props: RenderPageProps) => (
    <>
      {props.canvasLayer.children}
      {templateData?.signatures && (<div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '750px',
          justifyContent: 'center',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%',
        }}
      >
        {templateData.signatures.map((signData: Record<string, any>, index: number) => {
          let signComponent = (<p>Side {signData.side}</p>);
          if (signData.side === '1' && pdfFile.signTo) {
            signComponent = (<img src={pdfFile.signTo} alt="side1" style={{ width: '40px' }} />);
          }
          if (signData.side === '2' && pdfFile.signFrom) {
            signComponent = (<img src={pdfFile.signFrom} alt="side2" style={{ width: '40px' }} />);
          }
          if (signData.page === pageNumber) {
            return (<div
              key={`${signData.side} ${index}`}
              style={{
                position: 'absolute',
                left: `${signData.x}px`, // Specify the horizontal coordinate
                top: `${signData.y}px`, // Specify the vertical coordinate
                color: 'red', // Set the text color to red
              }}
            >
              {signComponent}
            </div>);
          }
        //    <div
        //   key={`${signData.side} ${index}`}
        //   style={{
        //     position: 'absolute',
        //     left: `${signData.x}px`, // Specify the horizontal coordinate
        //     top: `${signData.y}px`, // Specify the vertical coordinate
        //     color: 'red', // Set the text color to red
        //   }}
        // >
        //   Sign {signData.side}
        // </div>
        })}
      </div>)}
      {props.annotationLayer.children}
      {props.textLayer.children}
    </>
  );

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    top: 10,
    left: 'calc(-50% + 20px)',
    right: 'calc(50% + 20px)',
    '& .MuiStepConnector-line': {
      borderTopWidth: 2,
      borderColor: theme.palette.divider,
    },
    '&.Mui-active, &.Mui-completed': {
      '& .MuiStepConnector-line': {
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  function QontoStepIcon({ active, completed }: { active: boolean; completed: boolean }) {
    return (
      <Box
        sx={{
          zIndex: 9,
          width: 24,
          height: 24,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: active ? 'primary.main' : 'text.disabled',
        }}
      >
        {completed ? (
          <Iconify
            icon={'eva:checkmark-fill'}
            sx={{ zIndex: 1, width: 20, height: 20, color: 'primary.main' }}
          />
        ) : (
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              backgroundColor: 'currentColor',
            }}
          />
        )}
      </Box>
    );
  }


  const steps = [
    {
      label: 'Նախագիծ և ներքին ստորագրություն',
      // email: 'a.arshakyan@uwcdilijan.am',
      // email: 'vladimirdanielyan@gmail.com',
      type: 'edit',
      index: 0,
      processing: false,
    },
    {
      label: 'Հաճախորդի Ստորագրություն',
      email: 'vladimirdanielyan@gmail.com',
      type: 'sign',
      index: 1,
      processing: false,
    }
  ]

  const currentStep = pdfFile?.step ?? 0;

  const signDocument = () => {
    () => changeContractByUid(
      pdfFile.uid,
      { step: 1 },
      () => sendEmail(
        'vladimirdanielyan@gmail.com',
        'Your review/signature required for the contract' +
        ` https://rtarmenia.bisflow.io/app/active/contracts/${pdfFile?.uid}`,
        () => window.location.reload()
      ),
      (error) => console.error(error))
  }

  async function signPdfFile(signature: string, signAgain?: 'signTo' | 'signFrom', positionsX?: number, positionY?: number) {
    // let documentToFetch = pdfFile.attachment;
    // if (signAgain === "signTo" && pdfFile?.original_attachment) {
    //   const fileUrl = `pf0ArUxSiZNwqXZnun6rVZSkrRH2/contract/${id}/${pdfFile.original_attachment}`;
    //   const storage = getStorage();
    //   const pdfRef = ref(storage, fileUrl);
    //   await getDownloadURL(pdfRef).then((url) => { documentToFetch = url });
    // }
    // if (signAgain === 'signFrom') {
    //   const fileUrl = `pf0ArUxSiZNwqXZnun6rVZSkrRH2/contract/${id}/${id}.pdf`;
    //   const storage = getStorage();
    //   const pdfRef = ref(storage, fileUrl);
    //   await getDownloadURL(pdfRef).then((url) => { documentToFetch = url });
    // }
    // const existingPdfBytes = await fetch(documentToFetch)
    //   .then((res) => res.arrayBuffer());

    // const pdfDoc = await PDFDocument.load(existingPdfBytes);
    // const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    //
    // const pages = pdfDoc.getPages();
    // const lastPage = pages[pages.length - 1];
    // const { width, height } = lastPage.getSize();
    // const { width: documentScreenWidth, height: documentScreenHeight } = (pdfContainerRef?.current as any)?.getBoundingClientRect();
    // console.log(height);
    // console.log(positionY);
    // lastPage.drawText("DEMO!!! Signed by Bisflow.io", {
    //   x: 100,
    //   y: height / 2 + 300,
    //   size: 30,
    //   font: helveticaFont,
    //   color: rgb(0.95, 0.1, 0.1),
    //   rotate: degrees(-45),
    // });
    // const pngOrigImage = await pdfDoc.embedPng(signature);
    // const pngImage = pngOrigImage.scale(0.15);
    // const pngScaleImage = pngOrigImage.scale(0.3);
    //
    // const screenWidthScale = width / documentScreenWidth;
    // const screenHeightScale = height / documentScreenHeight;

    // if (templateData) {
    //   lastPage.drawImage(pngOrigImage, {
    //     x: positionsX ? (positionsX - (pngImage.width / 2)) * screenWidthScale : templateData['1'].x,      // Adjusted X coordinate
    //     y: positionY ? (((documentScreenHeight - positionY) * screenHeightScale) - (pngScaleImage.height / 2)): (templateData['1'].y - (pngImage.height / 2)), // Adjusted Y
    //     coordinate
        // width: pngImage.width,
        // height: pngImage.height,
      // });
      //
      // const pdfBytes = await pdfDoc.save();
      // const storage = getStorage();
      // const storageRef = ref(storage, `${selectedCompany.uid}/contract/${id}/${id}.pdf`);
      let signatureKey = 'signTo';
      const data = {
        ...(signAgain ? {} : {[signatureKey]: signature}),
        processing: false,
        status: (pdfFile?.step === 2 || signAgain === 'signFrom') ? 'Done' : 'External Signature',
        updated: Timestamp.now(),
        original_attachment: pdfFile.attachment,
        step: pdfFile?.step ? pdfFile.step + 1 : 0
      }
      // uploadBytes(storageRef, pdfBytes).then((snapshot) => {
        // setSignatureDialog(false);
        changeContractByUid(
          pdfFile?.uid,
          data,
          () => window.location.reload(),
          (error) => console.error(error)
        )
      // });
    }

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorSignatureEl, setAnchorSignatureEl] = useState(null);
  const open = Boolean(anchorEl);
  const openSignature = Boolean(anchorSignatureEl);

  // @ts-ignore
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value: string) => {
    setAnchorEl(null);
    updateDocument(
      pdfFile.uid,
      value,
      () => window.location.reload(),
      (error) => console.log(error),
    );
  };

  const handleSignatureAnchorClick = (event: any) => {
    setAnchorSignatureEl(event.currentTarget);
  };

  const handleSignatureAnchorClose = (value: string) => {
    setAnchorSignatureEl(null);
    updateDocument(
      pdfFile.uid,
      value,
      () => window.location.reload(),
      (error) => console.log(error),
    );
  };

  const sendSms = () => {
    // setAnchorEl(null);
    // sendSmsService(
    //   pdfFile?.to?.company_uid,
    //   pdfFile?.attachment
    // )
    // console.log(pdfFile)

    // const accountSid = 'AC12e8e0dd154fb84a76c39492e7577ece';
    // const authToken = '360db3e33607dc61c621b63286a40a73';
    // const client = require('twilio')(accountSid, authToken);
    //
    // client.messages
    //   .create({
    //     body: `Your contract is available at ${pdfFile.attachment ?? 'bisflow.io'}`,
    //     to: pdfFile?.to?.company_uid ?? '+37455195152'
    //   })
    //   .then(() => enqueueSnackbar('SMS հաջողությամբ ուղարկվել է', { variant: 'success' }))
    //   .catch((error: any) => console.error(error))
    //   .done();
  }

  const handleDocumentLoad = (event: DocumentLoadEvent) => {
    // Apply fullscreen style to the first page when the document loads
    const firstPage = document.querySelector(".rpv-core__page-layer");
    if (firstPage) {
      firstPage.classList.add("fullscreen-pdf-page");
    }
  };

  // if (isUploading) {
  //   return <LoadingScreen />
  // }

  const downloadPdfFile = async (url?: string, name?: string) => {
    if (url && name) {
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error(`Error: ${response.statusText}`);

        // Ensure the response is treated as a PDF blob
        const blob = await response.blob();
        // if (blob.type !== 'application/pdf') {
        //   throw new Error('The fetched file is not a PDF.');
        // }

        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.rel = 'noopener';
        link.download = name; // Set your desired file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        console.error('Error downloading the file:', error);
      }
    }
  }

  const sendEmailAgain = () => {
    // sendEmail(
    //   pdfFile?.to?.email ?? 'vlad@bisflow.io',
    //   `<!DOCTYPE html><html><head><title>Email with Image and PDF Link</title></head><body><img src="https://firebasestorage.googleapis.com/v0/b/bisflow.appspot.com/o/brands%2Frtarmenia%2F%D5%86%D5%A1%D5%B4%D5%A1%D5%AF%D5%AB%20%D5%A2%D5%B8%D5%BE%D5%A1%D5%B6%D5%A4%D5%A1%D5%AF%D5%B8%D6%82%D5%A9%D5%B5%D5%B8%D6%82%D5%B6.1.jpeg?alt=media&token=6065f85f-d78d-4084-98c7-e61c1733c89c" alt="Letter Content Image" style="width:100%; max-width:600px; height:auto;"></body></html>`,
    //   () => {
    //     enqueueSnackbar(translate('successSend'), { variant: 'success' });
    //     window.location.reload();
    //   },
    //   [{
    //     filename: 'RTarmenia_contract.pdf',
    //     path: pdfFile?.attachment
    //   }]
    // )
  }

  const handleSignatureAnchorSelect = (value: string) => {
    setAnchorSignatureEl(null);
    if (value === 'self' || value === 'customer') {
      setAllowedMouseMove(value);
    } else {
      setAllowedMouseMove(null);
    }
  }

  const [contactDataDialog, setContactDataDialog] = useState<boolean>(false);

  const handleOnContactChange = (newContact: ModelContact | null) => {
    setAnchorEl(null);
    if (pdfFile?.uid && newContact) {
      changeContractByUid(
        pdfFile?.uid,
        { to: { ...newContact } },
        () => window.location.reload(),
        (error) => console.log(error)
      )
    }
  }

  const handleMouseMove = (e: any) => {
    if (pdfContainerRef?.current) {
      const { left, top } = (pdfContainerRef.current as any).getBoundingClientRect();
      setPosition({ x: e.clientX - left, y: e.clientY - top });
    }
  };

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setImageSize({
      width: e.currentTarget.offsetWidth,
      height: e.currentTarget.offsetHeight
    });
  };

  const emptySignature = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wQAAwAB/1ofy5sAAAAASUVORK5CYII=';
  const handleSignatureConfirm = () => {
    if (allowedMouseMove) {
      const signAgain = allowedMouseMove === 'self' ? 'signTo' : 'signFrom';
      const xPosition = position.x - (imageSize.width * 0.3) / 2;
      const yPosition = position.y - (imageSize.height * 0.3) / 2;
      signPdfFile(pdfFile?.[signAgain], signAgain, xPosition, yPosition);
    }
  }

  /**
   * @description - The document creation view
   */
  return (
    <Grid container spacing={3}>
      {/*{pdfFile?.processing !== true && typeof pdfFile?.attachment === 'string' && (*/}
      {/*  <Viewer*/}
      {/*    fileUrl={pdfFile.attachment}*/}
      {/*    renderError={renderError}*/}
      {/*    key={Math.random()}*/}
      {/*    onDocumentLoad={handleDocumentLoad}*/}
      {/*  />)*/}
      {/*}*/}
      <DocumentNewContactDialog
        open={contactDataDialog}
        onClose={() => setContactDataDialog(false)}
        onSelect={handleOnContactChange}
        currentContact={pdfFile?.to}
      />
      <Dialog
        open={signatureDialog}
        onClose={() => setSignatureDialog(false)}
        fullWidth
        maxWidth="lg"
      >
        <SignatureDrawer
          onDialogClick={(base64Image: any) => {
            signPdfFile(pdfFile?.project_uid !== 'VMP2i8NpLIfyjJrU9Lgp' ? base64Image : emptySignature).then(() => fetchDocumentByUid(id ?? '', selectedCompany.uid, setPdfFile, (error) => console.log(error)));
          }}
          onSaveTemplateClick={(base64Image: string) => {
            if (base64Image) {
              updateDoc(doc(firestoreDb, 'users', user?.uid ?? 'na'), {
                signature: base64Image
              }).then(() => enqueueSnackbar('Your signature updated', { variant: 'success' }))
                .catch((error: Error) => enqueueSnackbar('Something went wrong', { variant: 'error' }))
            }
          }}
          onUseTemplate={() => {
            if (user?.signature) {
              signPdfFile(pdfFile?.project_uid !== 'VMP2i8NpLIfyjJrU9Lgp' ? user.signature : emptySignature).then(() => fetchDocumentByUid(id ?? '', selectedCompany.uid, setPdfFile, (error) => console.log(error)))
            } else {
              enqueueSnackbar('No signature found', { variant: 'error' })
            }
          }}
          onCancelDialog={() => setSignatureDialog(false)}
        />
      </Dialog>
      {pdfFile?.attachment && (<Grid item xs={12} md={12}>
        <Stack spacing={3}>
          <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
            <p />
            <Button
              variant="contained"
              sx={{ marginTop: 2, marginLeft: 2 }}
              onClick={() => setSignatureDialog(true)}
            >
              {translate('sign')}
            </Button>
            <div>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                {translate('customerActions')}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={() => handleClose('show')} value={'show'}>{translate('show')}</MenuItem>
                <MenuItem disabled={typeof pdfFile?.signTo !== 'string'} onClick={() => handleClose('sign')}
                          value={'sign'}>{translate('sign')}</MenuItem>
                <MenuItem disabled={typeof pdfFile?.signTo !== 'string'} onClick={() => handleClose('confirm')}
                          value={'confirm'}>{translate('confirm')}</MenuItem>
                <Divider/>
                <MenuItem onClick={() => setContactDataDialog(true)}
                          value={'changeContact'}>{translate('changeContactDetails')}</MenuItem>
                <Divider/>
                <MenuItem onClick={() => handleClose('none')} value={'confirm'}>{translate('cancel')}</MenuItem>
                <Divider/>
                <MenuItem onClick={() => sendEmailAgain()} value={'sendSms'}>{translate('sendEmail')}</MenuItem>
                <MenuItem onClick={sendSms} value={'sendSms'}>{translate('sendSms')}</MenuItem>
              </Menu>
            </div>
            <div>
              <Button
                aria-controls="simple-menu-signature"
                aria-haspopup="true"
                onClick={handleSignatureAnchorClick}
              >
                {translate('signatureActions')}
              </Button>
              <Menu
                id="simple-menu-signature"
                anchorEl={anchorSignatureEl}
                keepMounted
                open={openSignature}
                onClose={handleSignatureAnchorClose}
              >
                <MenuItem onClick={() => handleSignatureAnchorSelect('self')} value={'self'}>{translate('moveMySignature')}</MenuItem>
                <MenuItem onClick={() => handleSignatureAnchorSelect('customer')} value={'customer'}>{translate('moveCustomerSignature')}</MenuItem>
                <Divider/>
                <MenuItem onClick={() => handleSignatureAnchorSelect('reset')} value={'reset'}>{translate('reset')}</MenuItem>
              </Menu>
            </div>
          </Stack>
        </Stack>
      </Grid>)}
      <Grid item xs={12} md={12}>
        <div
          style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
          <GoToFirstPage/> {/* Button to go to the first page */}
          <GoToPreviousPage/> {/* Button to go to the previous page */}
          <CurrentPageInput/> {/* Input to enter a page number */}
          / <NumberOfPages/> {/* Shows the total number of pages */}
          <GoToNextPage/> {/* Button to go to the next page */}
          <GoToLastPage/> {/* Button to go to the last page */}
        </div>
      </Grid>
      <Grid item xs={12} md={12}>
        <div
          style={{
            height: '750px',
            width: '750px',
            padding: '18px',
          }}
        >
          {pdfFile?.attachment && (
            <Viewer
              fileUrl={!pdfFile.attachment?.includes('/') ? pdfFile?.original_attachment : pdfFile.attachment}
              plugins={[pageNavigationPluginInstance]}
              renderPage={renderPage}
              defaultScale={SpecialZoomLevel.PageFit}
              onPageChange={handlePageChange}
              theme={'dark'}
              initialPage={0}
              scrollMode={ScrollMode.Page}
              viewMode={ViewMode.SinglePage}
            />
          )}
        </div>
      </Grid>
    </Grid>)
}
