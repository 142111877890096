/**
 * @description - The Bisflow application content's translation
 */
// Common words like "Yes", "No", "Edit"
import enCommonContent from './en_common_words';
const enContentBisflow = {
  common: { ...enCommonContent },
  contracts: 'Contracts',
  newContract: 'New contract',
  files: 'Files',
  active: 'Active',
  archive: 'Archive',
  search: 'Search',
  internalSignature: 'Internal Signature',
  externalSignature: 'External Signature',
  signed: 'Signed',
  all: 'All',
  byId: 'By Customer ID',
  byName: 'By contract name',
  file: 'File',
  device: 'Device',
  client: 'Client',
  time: 'Time',
  status: 'Status',
  history: "History",
  show: 'Show',
  delete: 'Delete',
  cancel: 'Cancel',
  apply: 'Apply',
  customerIdExample: 'Customer ID - Ex. 1234567',
  customerFullName: 'Customer full name',
  customerEmail: 'Customer email',
  contractType: 'Contract Type',
  template: 'Template',
  saveAsDraft: 'Save as draft',
  uploadCustomerPassport: 'Upload customer passport',
  uploadContract: 'Upload Contract',
  uploadSupplements: 'Upload conditions',
  edit: 'Edit',
  selectDevice: 'Select Device',
  customer: 'Customer',
  add: 'Add',
  phoneNumber: 'Phone number',
  phoneValidation: 'Phone number should be 7 digits',
  fillCustomerDetails: 'Fill customer details',
  successSend: 'Success sent',
  sign: 'Sign',
  customerActions: 'Customer actions',
  signatureActions: 'Signature actions',
  moveMySignature: 'Move my signature',
  moveCustomerSignature: 'Move customer signature',
  reset: 'Reset',
  confirm: 'Confirm',
  changeContactDetails: 'Change contact details',
  sendEmail: 'Send email',
  sendSms: 'Send SMS',
  download: 'Download',
  erase: 'Erase',
  saveAsTemplate: 'Save as template',
  signByTemplate: 'Sign by template',
  showOpenContracts: 'Show Opened Contracts',
  opened: 'Opened',
  action: 'Action',
  close: 'Close',
};

export default enContentBisflow;
