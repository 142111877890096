/**
 * @description - The all services that are related to the documents' data
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import {
  getDoc,
  getDocs,
  Timestamp,
  setDoc,
  updateDoc,
  doc,
  collection,
  getFirestore,
  onSnapshot, query, where
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
// Queries
import {
  generateCollectionReference,
  generateDocumentReference,
  generateMailReference,
  queryDeviceUid,
  queryFetchAllDocumentsByProjectUid, queryRtarmeniaTemplates
} from './queries';
// Local type
import { TypeDocumentView, TypeDocumentStatus, ModelContract } from 'src/@types';


// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The method is deleting the contract by provided UID. It's simply changing visibility to be false
 * @param uid
 * @param onSuccess
 * @param onFail
 */
export function deleteContractByUid(
  uid: string,
  onSuccess: () => void,
  onFail: (error: Error | string | null) => void
) {
  const documentReference = generateDocumentReference('contract', uid);
  updateDoc(documentReference, { visible: false }).then(onSuccess).catch(onFail);
}

export function archiveContractByUid(
  uid: string,
  onSuccess: () => void,
  onFail: (error: Error | string | null) => void
) {
  const documentReference = generateDocumentReference('contract', uid);
  updateDoc(documentReference, { document_status: 'archive' }).then(onSuccess).catch(onFail);
}

/**
 * @description - The method is deleting the contract by provided UID. It's simply changing visibility to be false
 * @param uid
 * @param data
 * @param onSuccess
 * @param onFail
 */
export function changeContractByUid(
  uid: string,
  data: Record<string, any>,
  onSuccess: () => void,
  onFail: (error: Error | string | null) => void
) {
  const documentReference = generateDocumentReference('contract', uid);
  updateDoc(documentReference, data).then(onSuccess).catch(onFail);
}

export function sendEmail(
  to: string,
  message: string,
  onSuccess: () => void,
  attachments?: Record<string, string>[],
) {
  const mailReference = generateMailReference();
  setDoc(mailReference, {
    uid: mailReference.id,
    created: Timestamp.now(),
    updated: Timestamp.now(),
    to: [to],
    message: {
      subject: 'Contract status update',
      html: message,
      attachments: attachments
    },
  }).then(onSuccess).catch((error) => console.error(error.message));
}

/**
 * @description - The method is fetching all projects that are related to the appropriate user.
 * @param documentType - The document type that needs to fetch for the specific database
 * @param documentStatusType - The document status, either 'template', 'archive', etc. in order to fetch from proper
 *  collection.
 * @param onSuccess - On success method, is executing whenever the method completed successfully. Passing the result
 * list to the void function that will be used in the parent.
 * @param onFail - On failed method, is executing whenever there is any issue during the data fetching
 * @param companyUid - The selected company UID,
 * @param projectUid - The selected project UID,
 * @param userUid - The selected user uid which is doing the fetching process
 */
export function fetchAllDocumentsFromProject(
    documentType: TypeDocumentView,
    documentStatusType: TypeDocumentStatus,
    projectUid: string,
    companyUid: string,
    userUid: string,
    onSuccess: (allDocuments: ModelContract[]) => void,
    onFail: (error: Error | string) => void,
    statusFilter?: string | null,
) {
  // Generating query to fetch all documents by project uid
  const queryAllDocumentsByProject = queryFetchAllDocumentsByProjectUid(
    userUid,
    companyUid,
    projectUid,
    documentType,
    documentStatusType,
    statusFilter ?? undefined,
  );
  if (queryAllDocumentsByProject) {
    getDocs(queryAllDocumentsByProject)
      .then((querySnapshots) => {
        const allContractsList: ModelContract[] = [];
        querySnapshots.forEach((contractSnapshot) => {
          const contractData = contractSnapshot.data();
          if (contractData) {
            allContractsList.push({
              ...contractData,
              uid: contractData.uid,
              company_uid: contractData.company_uid,
              project_uid: contractData.project_uid,
              template_uid: contractData.template_uid,
              attachment: contractData.attachment,
              display_name: contractData.display_name,
              permissions: contractData.permissions,
              active: contractData.active,
              visible: contractData.visible,
              status: contractData.status,
              created: contractData.created,
              updated: contractData.updated,
              user_uid: contractData.user_uid
            });
          }
        })
        onSuccess(allContractsList);
      })
      .catch((error) => {onFail(error.message); console.error(error.message)});
  }
}

/**
 * @description - The method is uploading the file into proper directory for the request
 * @param uid - request uid
 * @param company_uid - company uid
 * @param collection_name - the collection name in order to save the project
 * @param file - the file
 * @param onSuccess - a method to execute when successfully uploaded
 * @param onFail - a method to execute when failed uploaded
 */
export function uploadFile(
  uid: string,
  company_uid: string,
  collection_name: string,
  file: File,
  onSuccess: (filePath: string) => void,
  onFail: (error: string) => void,
) {
  // Getting the storage of the file path
  const storage = getStorage();
  // Generating the file URL
  const fileUrl = `${company_uid}/${collection_name}/${uid}/${file.name}`;
  // Getting the file location firestore reference
  const pdfRef = ref(storage, fileUrl);
  // 'file' comes from the Blob or File API
  uploadBytes(pdfRef, file)
    .then((snapshot) => {
      onSuccess(fileUrl);
    })
    .catch((error) => onFail(error.message));
}

export async function uploadFiles(
  uid: string,
  company_uid: string,
  collection_name: string,
  files: (File | null)[],
  onSuccess: (filePath: string) => void,
  onFail: (error: string) => void
) {
  const storage = getStorage();
  let fileUrl = '';
  // Generating the file URL
  const filesAction = files.map((fileData) => {
    if (fileData) {
      fileUrl = `${company_uid}/${collection_name}/${uid}/${fileData.name}`;
      // Getting the file location firestore reference
      const pdfRef = ref(storage, fileUrl);
      return uploadBytes(pdfRef, fileData);
    }
  });
  return Promise.all(filesAction).catch((error) => onFail(error.message)).then(() => onSuccess(fileUrl));
}

export function getDownloadableUrl(inputPath: string, onSuccess: (finalUrl: string) => void) {
  const storage = getStorage();
  const storageRef = ref(storage, inputPath);

// Get the download URL
  getDownloadURL(storageRef)
    .then((url) => {
      // The download URL is the value of `url`
      onSuccess(url);
      // You can use this URL for downloading or displaying the file, for example, in an <img> tag
    })
    .catch((error) => {
      // Handle any errors
      console.error(error);
    });
}

export function updateDocument(
  uid: string,
  status: string,
  onSuccess: () => void,
  onFail: (error: string) => void
) {
  const documentRef = generateDocumentReference("contract", uid);

  try {
    updateDoc(documentRef, {statusProcessStage: status}).then(onSuccess).catch(onFail);
    console.log("Document successfully updated!");
  } catch (error) {
    console.error("Error updating document: ", error);
  }
}

export function handleUpdateTemplate(uid: string, fileUrl: string, onSuccess: VoidFunction) {
  updateDoc(doc(getFirestore(), 'none', uid), { fileUrl }).then(onSuccess).catch(console.log);
}

export function sendSmsService(
  phoneNumber: string,
  filePath: string,
) {
  const documentReference = doc(collection(getFirestore(), 'messages'));
  // Creating the document
  setDoc(
    documentReference,
    {
      from: '+12055709434',
      to: phoneNumber,
      body: filePath,
      uid: documentReference.id,
      active: true,
      created: Timestamp.now(),
      updated: Timestamp.now(),
    }
  )
    // .then(() => onSuccess(documentReference.id))
    // .catch(onFail);
}

export function getCustomTemplateRtarmenia(onSuccess: (data: Record<string, any>[]) => void) {
  const reference = queryRtarmeniaTemplates();
  getDocs(reference).then((docsSnapshot) => {
    const allContractsList: Record<string, any>[] = [];
    docsSnapshot.forEach((contractSnapshot) => {
      const contractData = contractSnapshot.data();
      if (contractData) {
        allContractsList.push({
          ...contractData,
        });
      }
    })
    onSuccess(allContractsList);
  }).catch((e) => console.log(e));
}

export function saveCustomTemplateRtarmenia(data: Record<string, any>, onSuccess: VoidFunction) {
  if (data?.attachment?.file) {
    const documentReference = generateDocumentReference('rttemplates');
    setDoc(
      documentReference,
      {
        project_uid: data.project_uid,
        signatures: data.signatures,
        display_name: data.display_name,
        uid: documentReference.id,
        active: true,
        visible: true,
        status: 'Draft',
        created: Timestamp.now(),
        updated: Timestamp.now(),
        created_by: data?.user_uid ?? null
      })
      .then(() => {
        uploadFiles(documentReference.id, 'pf0ArUxSiZNwqXZnun6rVZSkrRH2', 'new_templates', [data.attachment.file], (filePath: string) => handleUpdateTemplate(documentReference.id, filePath, onSuccess), (error) => console.log(error));
      })
      .catch(console.error);
  }
}

/**
 * @description - The method is saving
 * @param documentData
 * @param collectionName
 * @param onSuccess
 * @param onFail
 */
export function saveDocument(
  documentData: {
    company_uid: string;
    project_uid: string;
    template_uid: string | null;
    attachment: string | null;
    display_name: string;
    from: Record<string, any>;
    to: Record<string, any>;
    user_uid?: string;
    documents?: string | null;
  },
  collectionName: TypeDocumentView,
  onSuccess: (uid: string) => void,
  onFail: (error: string) => void
) {
  // The document reference
  const documentReference = generateDocumentReference(collectionName);
  // Creating the document
  setDoc(
    documentReference,
    {
      ...documentData,
      assigned_device: documentData.from.uid,
      attachment: documentData.attachment ?? documentData.display_name,
      original_attachment: documentData.attachment ?? documentData.display_name,
      uid: documentReference.id,
      permissions: [],
      active: true,
      visible: true,
      status: 'Draft',
      created: Timestamp.now(),
      updated: Timestamp.now(),
      document_status: 'new',
      created_by: documentData?.user_uid ?? null
    }
  )
    .then(() => onSuccess(documentReference.id))
    .catch(onFail);
}

export function getByAssigned(userId: string, onSuccess: (data: Record<string, any>) => void ) {
  const reference = queryDeviceUid(userId);
  getDocs(reference).then((docsSnapshot) => {
    const allContractsList: Record<string, any>[] = [];
    docsSnapshot.forEach((contractSnapshot) => {
      const contractData = contractSnapshot.data();
      if (contractData) {
        allContractsList.push({
          ...contractData,
        });
      }
    })
    onSuccess(allContractsList);
  }).catch((e) => console.log(e));
}
//
// export function listenDocumentBuUid(
//   uid:  string,
//   onSuccess: () => void,
//   onFail: (error: Error | string) => void
// ) {
//   onSnapshot(doc(collection(getFirestore(), 'contracts'), uid))
// }

export function getOpenedDevicesContracts(onSuccess: (data: any[]) => void) {
  const documentsDbReference = generateCollectionReference('contract');
  const q = query(
    documentsDbReference,
    // Checking company based documents
    where('active', '==', true),
    where('visible', '==', true),
    where("statusProcessStage", "in", ["show", "sign", "confirm"]),
  )

  getDocs(q).then((snapshots) => {
      const allContractsList: any[] = [];
      snapshots.forEach((contractSnapshot) => {
        const contractData = contractSnapshot.data();
        if (contractData) {
          allContractsList.push(contractData);
        }
      });
      onSuccess(allContractsList);
  }).catch(console.log)
}

export function fetchDocumentByUid(
  uid:  string,
  companyUid: string,
  onSuccess: (contract: any) => void,
  onFail: (error: Error | string) => void
) {
  const documentReference = generateDocumentReference('contract', uid);
  getDoc(documentReference).then((doc) => {
    if (doc.exists()) {
      const contractData = doc.data();
      const storage = getStorage();
      // Generating the file URL
      const fileUrl = `${companyUid}/contract/${uid}/${contractData?.attachment}`;
      // Getting the file location firestore reference
      const pdfRef = ref(storage, fileUrl);
      getDownloadURL(pdfRef).then((url) => {
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
        };
        xhr.open('GET', url);
        xhr.send();
        // ToDo add profile parsing method
        onSuccess({
          uid: contractData.uid,
          attachment: url,
          processing: contractData.processing ?? false,
          signFrom: contractData.signFrom ?? false,
          step: contractData.step,
          from: contractData.from,
          to: contractData.to,
          template_uid: contractData.template_uid ?? 'na',
          statusProcessStage: contractData.statusProcessStage,
          side2: contractData?.side2 ?? false,
          // account_type: userProfileDocument?.account_type ?? '', // If the user profile contains that field
          // created: userProfileDocument?.created ?? '', // If the user profile contains that field
          // updated: userProfileDocument?.updated ?? '', // If the user profile contains that field
          // display_name: userProfileDocument?.display_name ?? '',
          // first_name: userProfileDocument?.first_name ?? '', // If the user profile contains that field
          // last_name: userProfileDocument?.last_name ?? '', // If the user profile contains that field
          // middle_name: userProfileDocument?.middle_name ?? '', // If the user profile contains that field
          // email: userProfileDocument?.email ?? '',
          // phone_number: userProfileDocument?.phone_number ?? '',
          // photo_url: userProfileDocument?.photo_url ?? null,
          // signature: userProfileDocument?.signature ?? '',
        });
      }).catch((error)  => console.error(error));
    }
  })
    .catch((error) => {
      console.error(error);
      onFail(error);
      // ToDo redirect to the profile can't read page and notify user
    });
}

export function fetchDocumentByUidNew(
  uid:  string,
  companyUid: string,
  onSuccess: (contract: any) => void,
  onFail: (error: Error | string) => void
) {
  const documentReference = generateDocumentReference('contract', uid);
  getDoc(documentReference).then((doc) => {
    if (doc.exists()) {
      const contractData = doc.data();
      const storage = getStorage();
      // Generating the file URL
      const fileUrl = `${companyUid}/contract/${uid}/${contractData?.attachment}`;
      const fileUrlDocuments = `${companyUid}/contract/${uid}/${contractData?.documents}`;
      const fileUrlSupplements = `${companyUid}/contract/${uid}/${contractData?.supplements}`;
      // Getting the file location firestore reference
      Promise.all([
        getDownloadURL(ref(storage, fileUrl)),
        contractData?.documents && getDownloadURL(ref(storage, fileUrlDocuments)),
        contractData?.supplements && getDownloadURL(ref(storage, fileUrlSupplements)),
      ]).then(([contractUrl, idUrl, supplementsUrl]) => {
        onSuccess({
          uid: contractData.uid,
          attachment: contractUrl,
          original_attachment: contractData.original_attachment ?? null,
          processing: contractData.processing ?? false,
          signFrom: contractData.signFrom ?? false,
          step: contractData.step,
          from: contractData.from,
          to: contractData.to,
          template_uid: contractData.template_uid ?? 'na',
          statusProcessStage: contractData.statusProcessStage,
          documents: idUrl || null,
          supplements: supplementsUrl || null,
          documents_type: contractData.documents_type || null,
          signTo: contractData.signTo ?? null,
          project_uid: contractData.project_uid ?? null,
          // account_type: userProfileDocument?.account_type ?? '', // If the user profile contains that field
          // created: userProfileDocument?.created ?? '', // If the user profile contains that field
          // updated: userProfileDocument?.updated ?? '', // If the user profile contains that field
          // display_name: userProfileDocument?.display_name ?? '',
          // first_name: userProfileDocument?.first_name ?? '', // If the user profile contains that field
          // last_name: userProfileDocument?.last_name ?? '', // If the user profile contains that field
          // middle_name: userProfileDocument?.middle_name ?? '', // If the user profile contains that field
          // email: userProfileDocument?.email ?? '',
          // phone_number: userProfileDocument?.phone_number ?? '',
          // photo_url: userProfileDocument?.photo_url ?? null,
          // signature: userProfileDocument?.signature ?? '',
        });
      }).catch((error)  => console.error(error));
      // getDownloadURL(pdfRef).then((url) => {
      //   // This can be downloaded directly:
      //   // const xhr = new XMLHttpRequest();
      //   // xhr.responseType = 'blob';
      //   // xhr.onload = (event) => {
      //   //   const blob = xhr.response;
      //   // };
      //   // xhr.open('GET', url);
      //   // xhr.send();
      //   // ToDo add profile parsing method
      //   if (!contractData?.documents) {
      //
      //   } else {
      //     const newPdfRef = ref(storage, fileUrlDocuments);
      //     getDownloadURL(newPdfRef).then((newUrl) => {
      //       onSuccess({
      //         uid: contractData.uid,
      //         attachment: url,
      //         processing: contractData.processing ?? false,
      //         signFrom: contractData.signFrom ?? false,
      //         step: contractData.step,
      //         from: contractData.from,
      //         to: contractData.to,
      //         template_uid: contractData.template_uid ?? 'na',
      //         statusProcessStage: contractData.statusProcessStage,
      //         documents: newUrl,
      //         // account_type: userProfileDocument?.account_type ?? '', // If the user profile contains that field
      //         // created: userProfileDocument?.created ?? '', // If the user profile contains that field
      //         // updated: userProfileDocument?.updated ?? '', // If the user profile contains that field
      //         // display_name: userProfileDocument?.display_name ?? '',
      //         // first_name: userProfileDocument?.first_name ?? '', // If the user profile contains that field
      //         // last_name: userProfileDocument?.last_name ?? '', // If the user profile contains that field
      //         // middle_name: userProfileDocument?.middle_name ?? '', // If the user profile contains that field
      //         // email: userProfileDocument?.email ?? '',
      //         // phone_number: userProfileDocument?.phone_number ?? '',
      //         // photo_url: userProfileDocument?.photo_url ?? null,
      //         // signature: userProfileDocument?.signature ?? '',
      //       });
      //     })
        }
      }).catch((error)  => console.error(error));
    }
  // })
  //   .catch((error) => {
  //     console.error(error);
  //     onFail(error);
  //     // ToDo redirect to the profile can't read page and notify user
  //   });
// }
